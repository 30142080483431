import React from "react";
import {colors} from "../../style";
import {Helmet} from "react-helmet";

// Importing favicon icons
import favIco from "../../assets/images/favicon.ico";
import favIco180 from "../../assets/images/apple-touch-icon.png";
import favIco32 from "../../assets/images/favicon-32x32.png";
import favIco16 from "../../assets/images/favicon-16x16.png";

const Head = () => {
  const author = "CPOEM";
  const description = `An initiative by practicing oncologists to disseminate knowledge in 
  collaboration with leading centers globally by bringing together renowned experts and leaders in Oncology.`;
  const title = "CPOEM - Creative Portal for Oncology Education and More";

  return (
    <Helmet
      htmlAttributes={{
        lang: "en"
      }}
      title={title}
      meta = {[
        {'http-equiv': "X-UA-Compatible", content: "IE=9"},
        {'http-equiv': "content-type", content: "text/html; charset=UTF-8"},
        {charset: "UTF-8"},
        {name: "viewport", content: "width=device-width, initial-scale=1, user-scalable=no"},
        {name: "author", content: author},
        {name: "description", content: description},

        // Theme color
        {property: "theme-color", content: colors.blue},
        {property: "msapplication-navbutton-color", content: colors.blue},
        {property: "apple-mobile-web-app-status-bar-style", content: colors.blue}
      ]}

      link={[
        {rel: 'icon', type:"image/x-icon", href: favIco},
        {rel: 'apple-touch-icon', sizes:"180x180", href: favIco180},
        {rel: 'icon', type:"image/png", sizes:"32x32", href: favIco32},
        {rel: 'icon', type:"image/png", sizes:"16x16", href: favIco16}
    ]}
    />
  )
};

export default Head;