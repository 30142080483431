import styled from "styled-components";
import {colors, variables} from "./colors";
import knowledgeBackground from "../assets/images/knowledge-background.svg";

export const MainContainer = styled.main.attrs({
  className: "main-container"
}) `
  max-width: ${variables.containerWidth}px;
  margin: auto;
  section.knowledge{
    overflow: hidden;
  }
  .section-spacing{
    padding-top: 250px;
  }
  @media (max-width: ${variables.containerWidth}px) {
    padding: 0 30px;
    .section-spacing{
      padding-top: 180px;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .section-spacing{
      padding-top: 120px;
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    padding: 0 15px;
  }
`;

export const Header = styled.header.attrs({
  className: "header"
})`
  padding: 25px 50px;
  .logo{
    margin: 0;
    display: flex;
    .logo-image{
      max-width: 78px;
    }
    .logo-text{
      margin-left: 22px;
      max-width: 110px
    }
  }
  .menu-list{
    .hamberger{
      display: none;
    }
    ul{
      li{
        margin-left: 22px;
        a{
          color: ${colors.black};
          font-weight: 400;
        }
      }
      li:first-child{
        margin-left: 0;
      }
    }
  }
  @media (max-width: ${variables.containerWidth}px) {
    margin: 0 -30px;
    padding: 25px 40px;
  }
  @media (max-width: ${variables.containerWidth - 1}px) {
    margin: 0 -30px;
    padding: 20px;
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    .logo{
      .logo-image{
        max-width: 60px;
      }
      .logo-text{
        margin-left: 15px;
        max-width: 85px;
      }
    }
    .menu-list{
      ul{
        li{
          margin-left: 15px;
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .logo{
      .logo-image{
        max-width: 55px;
      }
      .logo-text{
        max-width: 85px;
      }
    }
    .menu-list{
      .hamberger{
        display: grid;
        grid-template-columns: 1fr;
        width: 45px;
        grid-row-gap: 10px;
        min-height: 35px;

        .bar{
          height: 5px;
          background: ${colors.black};
          width: 100%;
          opacity: 1;
          transition: all 0.4s ease;
        }
      }
      ul{
        display: none;
        position: absolute;
        top: 95px;
        background: ${colors.bodyBackground};
        left: 0;
        width: 100%;
        flex-direction: column;
        z-index: 200;
        align-items: flex-start;
        li:first-child{
          border-top: 1px solid ${colors.black};
        }
        li{
          width: 100%;
          margin: 0;
          padding: 15px 25px;
          border-bottom: 1px solid ${colors.black};
        }
      }
    }
    .menu-list.open{
      .hamberger{
        position: relative;
        .bar{
          position: absolute;
          top: 15px;
          transition: all 0.4s ease;
        }
        .one{
          transform: rotate(45deg);
        }
        .three{
          transform: rotate(-45deg);
        }
        .two{
          opacity: 0;
        }
      }
      ul{
        display: block;
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    margin: 0 -15px;
    padding: 20px 15px;
    .menu-list{
      .hamberger{
        width: 40px;
        grid-row-gap: 6px;
        min-height: 27px;
      }
    }
  }
`;

export const FullWidthContainer = styled.section`
  &.home{
    .black-box{
      min-height: 80px;
      background-color: ${colors.black};
      color: ${colors.white};
      align-items: center;
      .event-details{
        span{
          margin-left: 10px
        }
        span.bold{
          font-weight: 500
        }
        span.small{
          font-size: 0.83em;
        }
        span.no-margin{
          margin: 0
        }
      }
      .register-button{
        padding-right: 85px
      }
    }
    .link-block{
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 0;
      grid-column-gap: 0;
      svg{
        max-height: 75%;
        width: auto;
      }
    }
  }

  &.knowledge{
    .grid-container{
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-bottom: 230px;
    }
    .grid-container:after{
      content: "";
      width: 450px;
      height: 450px;
      background-image: url(${knowledgeBackground});
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      right: -225px;
      z-index: -1;
    }
  }

  &.our-goal{
    padding: 90px 0 0;
  }

  &.cpoem-team-page{
    padding-top: 200px;
    >.logo{
      margin-bottom: -125px;
    }
  }

  @media (max-width: ${variables.containerWidth}px) {
    &.home{
      margin: 0 -30px;
    }
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    &.home{
      .black-box{
        .register-button{
          padding: 0 20px;
        }
      }
    }
    &.knowledge{
      .grid-container:after{
        width: 320px;
        height: 320px;
        right: -160px;
      }
    }
    &.cpoem-team-page{
      padding-top: 100px;
      >.logo{
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    &.home{
      .black-box{
        .register-button{
          padding-right: 0;
        }
        padding: 10px 15px;
        .event-details{
          text-align: left;
          padding-right: 15px;
          span{
            margin-left: 5px;
          }
        }
      }
      .link-block{
        grid-template-columns: 1fr 1fr;
      }
    }
    &.knowledge{
      .grid-container{
        grid-template-columns: 1fr 1fr;
        padding-bottom: 170px;
      }
      .grid-container:after{
        width: 280px;
        height: 280px;
        right: -140px;
      }
    }
    &.cpoem-team-page{
      padding-top: 70px;
      >.logo{
        margin-bottom: 25px;
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    &.home{
      margin: 0 -15px;
    }
    &.knowledge{
      .grid-container{
        grid-row-gap: 5px;
        grid-column-gap: 5px;
        padding-bottom: 150px;
      }
      .grid-container:after{
        width: 260px;
        height: 260px;
        right: -130px;
      }
    }
  }
`;

export const HomeBlockBox = styled.div.attrs({
  className: "home-block-box"
})`
  background-color: ${props => props.background};
  padding-top: 100%;
  position: relative;
  .content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
  }
  .icon{
    position: absolute;
  }
`;

export const WebinarContainer = styled.section`
.grid-container{
  grid-template-areas: 
    'recent recent'
    'upcoming upcoming';
  grid-template-columns: 1fr 1fr;
  .webinar{
    color: ${colors.white};
    padding: 60px;
    background-color: ${colors.pink};
    .date{
      font-size: 1.4em;
    }
    .title{
      font-weight: 500;
      font-size: 2.5em;
      line-height: 1;
    }
    .desc-container{
      margin-top: 35px;
      .desc{
        font-size: 1.2em;
        line-height: 1.2;
      }
      .highlights{
        margin-top: 35px;
        span{
          position: relative;
          padding: 0 10px;
          hite-space: nowrap;
        }
        span:before{
          content: "|";
          position: absolute;
          left: 0;
        }
        span:first-child:before{
          content: "";
        }
      }
    }
    .speakers{
      .speaker{
        margin-right: 20px;
      }
      .speaker:last-child{
        margin-right: 0
      }
    }
    .agenda{
      .heading{
        font-size: 2.59em;
      }
      .doctor-name{
        margin-top: 10px
      }
      ul{
        margin-top: 35px;
        li{
          margin-bottom: 30px;
          .image{
            .doctor-pic{
              max-width: 150px;
              display: block;
            }
          }
          .agenda-details{
            padding-left: 20px;
            line-height: 1.1;
            font-weight: 500;
            .agenda-desc{
              max-height: 90px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        li:last-child{
          margin-bottom: 0
        }
      }
    }
    .bottom-part{
      margin-top: 25px;
      flex-grow: 1;
      .btn-container{
        margin-top: 30px;
        .btn{
          color: ${colors.pink};
        }
      }
    }
  }
  .webinar.recent{
    grid-area: recent;
    background-color: ${colors.blue};
    .title{
      font-size: 4em;
      padding-right: 40px;
    }
    .desc-container{
      padding-right: 40px;
    }
    .flex-container{
      >.flexbox:first-child{
        width: 65%;
      }
      >.flexbox.agenda{
        width: 35%;
      }
    }
    .btn-container{
      margin-top: 0;
      .btn{
        color: ${colors.blue};
      }
    }
  }
  .webinar:last-child{
    background-color: ${colors.purple};
    .btn-container{
      .btn{
        color: ${colors.purple};
      }
    }
  }
}
@media (min-width: ${variables.breakpoint}px) and (max-width: ${variables.containerWidth - 1}px) {
  .grid-container{
    .webinar{
      padding: 40px;
      .bottom-part .btn-container {
        margin-top: 20px;
      }
      .desc-container{
        padding: 0;
      }
    }
    .webinar.recent{
      .flex-container{
        >.flexbox:first-child{
          width: 60%;
          padding-right: 25px;
        }
        >.flexbox.agenda{
          width: 40%;
        }
      }
    }
  }
}
@media (max-width: ${variables.breakpoint}px) {
  .grid-container{
    .webinar{
      padding: 25px;
      .desc-container{
        margin-top: 20px;
        max-width: 100%;
        .highlights{
          margin-top: 20px;
        }
        .desc{
          font-size: 1.3em;
        }
      }
      .bottom-part{
        margin-top: 20px;
        .btn-container{
          margin-top: 20px;
        }
      }
    }
    .webinar.recent{
      >.flex-container{
        flex-direction: column;
        >.flexbox{
          width: 100% !important;
        }
      }
      .title{
        font-size: 3em;
        padding: 0;
      }
      .desc-container{
        padding: 0;
      }
      .agenda{
        .heading{
          display: none;
        }
        ul li{
          .agenda-details{
            padding-left: 15px;
            .agenda-desc {
              max-height: 80px;
            }
          }
          .image{
            .doctor-pic{
              max-width: 110px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: ${variables.mobilePortrait}px) {
  .grid-container{
    grid-template-areas: 
    'recent'
    'upcoming'
    'upcoming';
    grid-template-columns: 1fr;
    .webinar.recent{
      .agenda{
        ul li .agenda-details .agenda-desc {
          max-height: 72px;
        }
      }
    }
  }
}
@media (max-width: ${variables.mobileSmallScreen}px) {
  .grid-container{
    .webinar{
      padding: 20px;
    }
    .webinar.recent{
      .agenda{
        ul li{
          .agenda-details{
            padding-left: 10px;
          }
          .image{
            .doctor-pic{
              max-width: 100px;
            }
          }
        }
      }
    }
  }
} 
`;

export const TestimonialContainer = styled.section`
  padding: 120px 0 100px;
  .swiper-container{
    .testimonial-box{
      .text-container{
        background: ${colors.grey};
        padding: 85px 45px;
        height: calc(100% - 170px);
        display: flex;
        flex-direction: column;
        .testimonial{
          font-size: 1.25em;
          line-height: 1.1;
          height: 100%
        }
        .doctor-hospital{
          margin-top: 105px;
          line-height: 1.1
        }
      }
      .text-container:after{
        content: "";
        position: absolute;
        bottom: -45px;
        right: 160px;
        width: 0; 
        height: 0; 
        border-top: 55px solid transparent;
        border-bottom: 55px solid transparent;
        border-right: 55px solid ${colors.grey};
        transform: rotate(-20deg);
        z-index: -1
      }
      .pic-container{
        margin-top: 40px;
        .doctor{
          width: 130px;
          border-radius: 50%;
          margin-left: auto;
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: ${variables.breakpoint - 1}px) {
    .swiper-container{
      .testimonial-box{
        .text-container{
          padding: 50px 35px;
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    overflow: hidden;
    padding: 140px 0 100px;
    .swiper-container{
      .testimonial-box{
        .text-container{
          padding: 40px 25px;
          .doctor-hospital{
            margin-top: 70px;
          }
        }
      }
    }
  }
`;

export const PastEventContainer = styled.section`
  .grid-container{
    grid-template-columns: 1fr 1fr;
    .event{
      padding: 65px 35px 80px;
      line-height: 1.1;
      a{
        color: ${colors.white};
      }
      .name{
        font-size: 2.29em;
        font-weight: 500;
      }
      .speaker{
        font-size: 1.37em;
        margin-top: 40px;
      }
    }
    .event:nth-child(6n+1){
      background-color: ${colors.violet};
    }
    .event:nth-child(6n+2){
      background-color: ${colors.green};
    }
    .event:nth-child(6n+3){
      background-color: ${colors.pink};
    }
    .event:nth-child(6n+4){
      background-color: ${colors.blue};
    }
    .event:nth-child(6n+5){
      background-color: ${colors.skyblue};
    }
    .event:nth-child(6n+6){
      background-color: ${colors.purple};
    }
  }
  @media (max-width: ${variables.breakpoint}px) {
    .grid-container{
      grid-template-columns: 1fr;
      .event{
        padding: 25px;
        .name{
          font-size: 1.9em
        }
        .speaker{
          font-size: 1.2em;
          margin-top: 20px;
        }
      }
    }
  }
`;

export const PatientSupportContainer = styled.section`
  padding-top: 130px;
  ul{
    li{
      padding: 20px 25px;
      font-size: 3.03em;
      margin-bottom: 25px;
      background-color: ${colors.blue};
      line-height: 1.1;
      a{
        color: ${colors.white};
      }
    }
    li:last-child{
      margin-bottom: 0;
      background-color: ${colors.purple};
    }
    li:nth-last-child(2){
      background-color: ${colors.purple};
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding: 0;
    ul{
      li{
        padding: 15px;
        font-size: 1.5em
      }
    }
  }
`;

export const KnowledgeBox = styled.div`
  background-color: ${props => props.background};
  position: relative;
  padding-top: 100%;
  .content{
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3{
      color: ${colors.white};
      font-size: 1.58em;
      line-height: 1.1;
      font-weight: 500
    }
  }
  @media (min-width: ${variables.breakpoint}px) and (max-width: ${variables.containerWidth}px) {
    .content{
      padding: 15px;
      h3{
        font-size: 1.3em;
      }
    }
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    .content{
      padding: 15px;
      h3{
        font-size: 1em;
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .content{
      padding: 10px;
      h3{
        font-size: 1.05em;
      }
    }
    &:empty{
      display: none;
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    .content{
      padding: 10px;
      h3{
        font-size: 1em;
      }
    }
    .btn{
      font-size: .95em
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) and (orientation: landscape) {
    .content{
      padding: 20px;
      h3{
        font-size: 2em;
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) and (orientation: landscape) {
    .content{
      padding: 15px;
      h3{
        font-size: 1.8em;
      }
    }
    .btn{
      font-size: 1em
    }
  }
`;

export const CenteredContainer = styled.section`
  padding: 75px 50px 120px 25%;
  .logo{
    max-width: 250px;
  }
  .heading-text{
    font-size: 3.75em;
    line-height: 1.05;
    margin-bottom: 50px;
    max-width: 750px;
    span{
      font-weight: 600;
      display: block;
    }
    span{
      .blue{
        color: ${colors.blue};
      }
      .green{
        color: ${colors.green};
      }
      .pink{
        color: ${colors.pink};
      }
      .violet{
        color: ${colors.violet};
      }
      .sky{
        color: ${colors.sky};
      }
    }
  }
  .small-text{
    font-size: 1.56em;
    max-width: 725px
  }
  &.interactive{
    .heading-text{
      margin: 0
    }
  }
  &.patient-support{
    .heading-text{
      span{
        display: initial;
        margin-left: 18px;
      }
    }
  }
  &.knowledge{
    .img-container{
      padding-right: 40px;
    }
  }
  &.contact-us{
    .contact-detail-container{
      margin-top: 40px;
      .heading{
        font-weight: 500;
        font-size: 1.79em;
      }
      p{
        padding-right: 50px;
        a{
          color: ${colors.blue};
        }
      }
    }
  }
  @media (min-width: ${variables.breakpoint}px) and (max-width: ${variables.containerWidth}px) {
    margin: 0 -30px;
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    padding: 75px 50px 120px 20%;
  }
  @media (max-width: ${variables.breakpoint}px) {
    &.knowledge{
      >.flex-container{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        .img-container{
          padding-right: 0;
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding: 50px 0 95px;
    .logo{
      max-width: 200px;
    }
    .heading-text{
      margin-bottom: 40px;
    }
    &.patient-support{
      padding-bottom: 65px
    }
    &.contact-us{
      .contact-detail-container{
        flex-direction: column;
        >.flexbox{
          width: 100%;
          margin-bottom: 40px;
        }
        >.flexbox:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    padding: 50px 0 95px;
    .logo{
      max-width: 150px;
    }
    .heading-text{
      font-size: 3em;
    }
  }
`;

export const TeamBlock = styled.div`
  padding-top: 115px;
  .team-blocks{
    margin-bottom: 75px;
    .team{
      max-width: 450px;
    }
    .team-details{
      padding-left: 55px;
      line-height: 1.1;
      .name{
        font-weight: 500;
        font-size: 1.79em;
      }
      .qualification{
        margin-top: 25px;
        font-size: 1.33em;
      }
      .btn{
        margin-top: 30px;
      }
    }
  }
  .team-blocks:last-child{
    margin-bottom: 0;
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.containerWidth - 1}px){
    margin-right: -30px;
    .team-blocks{
      >.flexbox{
        width: 50%;
        .team{
          max-width: 100%;
        }
      }
      .team-details{
        padding-left: 20px;
        .name{
          font-size: 1.5em;
        }
        .qualification{
          font-size: 1.25em;
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding-top: 50px;
  }
  @media (max-width: ${variables.mobilePortrait}px) and (orientation: portrait){
    .team-blocks{ 
      flex-direction: column;
      align-items: flex-start;
      .team {
        max-width: 100%;
      }
      .team-details{
        padding-top: 20px;
        padding-left: 0;
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) and (orientation: landscape) {
    .team-blocks{
      >.flexbox{
        width: 50%;
        .team{
          max-width: 100%;
        }
      }
      .team-details{
        padding-left: 30px;
      }
    }
  }
`;

export const AdvisorContainer = styled.section`
  .advisors{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    .advisor{
      line-height: 1.1;
      img{
        margin: auto;
        max-width: 265px;
      }
      .name{
        margin-top: 30px;
      }
    }
  }
  @media (min-width: ${variables.breakpoint}px) and
  (max-width: ${variables.containerWidth - 1}px){
    .advisors{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: ${variables.breakpoint}px) {
    .advisors{
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .advisors{
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      .advisor{
        img{
          max-width: 100%
        }
        .name{
          margin-top: 15px;
        }
      }
    }
  }
`;

export const OurPartnerContainer = styled.section`
  padding-bottom: 125px;
  
  .partner-list{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    li{
      padding: 0 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.white};
      img.partner {
        margin: auto;
        max-width: 100%;
      }
    }
    li.bms{
      padding: 0 22px;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding-bottom: 140px;
    .partner-list{
      grid-template-columns: 1fr;
      .break{
        display: none;
      }
      li{
        padding: 25px 30px;
      }
      li.bms{
        padding: 25px 30px;
      }
    }
  }
`;

export const CPOEMNewsLetterContainer = styled.section`
  background: ${colors.pink};
  padding: 75px;
  color: ${colors.white};
  h2{
    font-size: 3.75em;
    font-weight: 500;
    line-height: 1.1
  }
  .padded-container{
    padding-left: 65px;
    p{
      font-size: 1.628em;
      line-height: 1.2;
    }
    .btn{
      margin-top: 75px;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding: 25px;
    >.flex-container{
      flex-direction: column;
      align-items: flex-start;
      >.flexbox.half-width{
        width: 100%;
        padding: 0
      }
    }
    .padded-container{
      p, .btn{
        margin-top: 20px;
      }
    }
  }
`;

export const OurMissionVisionContainer = styled.section`
  padding: 90px 0;
  .photo{
    grid-area: photo;
    img{
      max-width: 350px;
      margin: auto;
    }
  }
  .mission{
    grid-area: mission;
    background-color: ${colors.purple};
  }
  .vision{
    grid-area: vision;
    background-color: ${colors.pink};
  }
  &.grid-container{
    grid-template-areas: 
    'photo mission'
    'vision mission';
    color: ${colors.white};
    .grid{
      padding: 50px 55px 55px;
      p{
        margin-bottom: 30px;
        font-size: 1.5em;
        line-height: 1.2
      }
      p:last-child{
        margin-bottom: 0
      }
      .heading{
        font-size: 3.29em;
        line-height: 1.1;
        margin-bottom: 25px;
        font-weight: 400;
      }
    }
  }
  @media (max-width: ${variables.breakpoint}px) {
    padding: 0;
    .photo{
      img{
        max-width: 100%;
      }
    }
    &.grid-container{
      grid-template-areas: 
      'photo'
      'vision'
      'mission';
      .grid{
        padding: 20px 25px 25px;
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    &.grid-container{
      position: relative;
      margin-bottom: 180px;
      padding-bottom: 30px;
      background: ${colors.bodyBackground};
      grid-template-areas: 
      'vision'
      'mission';
      .grid{
        z-index: 1;
      }
      .photo {
        position: absolute;
        padding: 0;
        bottom: -180px;
        left: 0;
        z-index: -1;
        right: 0;
      }
    }
  }
`;

export const TheCPOEMTeamContainer = styled.div`
  .doctor-desc{
    grid-area: doctor;
    .name{
      font-size: 1.79em;
      font-weight: 600
    }
    .qualification{
      margin-top: 10px;
      font-size: 1.33em;
      white-space: pre-line;
    }
  }
  .photo{
    grid-area: photo;
    img{
      max-width: 100%;
    }
  }
  .expertise{
    grid-area: expertise;
    .block-heading{
      background-color: ${colors.blue};
    }
  }
  .research-interests{
    grid-area: research;
    .block-heading{
      background-color: ${colors.green};
    }
  }
  .recognition{
    grid-area: recognition;
    .block-heading{
      background-color: ${colors.pink};
    }
  }
  &.grid-container{
    grid-template-areas: 
      'doctor doctor photo'
      'expertise research recognition';
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    margin-bottom: 75px;
    .expertise, .research-interests, .recognition{
      background-color: ${colors.background};
      .block-heading{
        padding: 10px 25px;
        color: ${colors.white};
      }
      .list{
        padding: 25px 30px 30px;
        li{
          margin-bottom: 30px;
        }
        li:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  &.grid-container:last-child{
    margin-bottom: 0;
  }
  @media (min-width: ${variables.mobilePortrait}px) and 
  (max-width: ${variables.breakpoint}px) {
    &.grid-container{
      grid-template-areas: 
      'doctor photo'
      'expertise expertise'
      'research research'
      'recognition recognition';
      grid-template-columns: 1fr 1fr;
      .expertise, .research-interests, .recognition{
        .block-heading{
          padding: 10px 20px;
        }
        .list{
          padding: 15px 20px 20px;
          li{
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    &.grid-container{
      grid-template-areas: 
      'photo'
      'doctor'
      'expertise'
      'research'
      'recognition';
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      .expertise, .research-interests, .recognition{
        .block-heading{
          padding: 10px 20px;
        }
        .list{
          padding: 15px 20px 20px;
          li{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

export const OurGoalContainer = styled.section`
  padding: 90px 0 40px;
  border-bottom: 5px solid ${props => props.border};
  img{
    margin: auto;
    max-width: 100%;
  }
  .goal-heading{
    font-size: 2.5em;
    font-weight: 500;
    margin-top: 20px;
    line-height: 1.1;
  }
  .goal-desc{
    font-size: 1.54em;
    max-width: 1110px;
    margin: auto;
    margin-top: 15px;
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding: 50px 0 40px;
  }
`;

export const TheTeamAdvisorContainer = styled.section`
  padding-bottom: 200px;
  .advisor-list{
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    .advisor{
      background-color: ${colors.background};
      .image{
        img{
          max-width: 265px;
        }
      }
      .name-qualification{
        padding: 0 30px;
        .name{
          font-weight: 600;
          font-size: 1.45em;
        }
        .qualification{
          font-size: 1.25em;
        }
      }
      .advisor-details{
        padding: 50px;
        font-size: 1.16em;
      }
    }
  }
  @media (min-width: ${variables.mobilePortrait}px) and 
  (max-width: ${variables.breakpoint}px) {
    padding-bottom: 180px;
    .advisor-list{
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding-bottom: 140px;
    .advisor-list{
      grid-template-columns: 1fr;
      .advisor{
        >.flex-container{
          flex-direction: column;
          align-items: center;
          .image{

          }
          .name-qualification{
            margin-top: 15px;
            padding: 0;
            text-align: center;
          }
        }
        .advisor-details{
          padding: 25px;
        }
      }
    }
  }
`;

export const VideoFilterContainer = styled.section`
  .question-block{
    margin-bottom: 130px;
    .question{
      margin-bottom: 10px;
      line-height: 1.1;
      padding: 20px 0 25px;
      font-size: 2.41em;
      color: ${colors.white};
      background: ${colors.blue};
    }
    .answers{
      margin: 0 -5px;
      .answer{
        width: calc(20% - 10px);
        margin: 0 5px 10px;
        font-size: 1.29em;
        cursor: pointer;
        padding: 15px;
        color: ${colors.white};
        border: 0;
        font-family: "BarlowCondensed";
        line-height: 1.1;
      }
      .answer:nth-child(7n+1){
        background-color: ${colors.violet};
      }
      .answer:nth-child(7n+2){
        background-color: ${colors.pink};
      }
      .answer:nth-child(7n+3){
        background-color: ${colors.blue};
      }
      .answer:nth-child(7n+4){
        background-color: ${colors.purple};
      }
      .answer:nth-child(7n+5){
        background-color: ${colors.deepGreen};
      }
      .answer:nth-child(7n+6){
        background-color: ${colors.sky};
      }
      .answer:nth-child(7n+7){
        background-color: ${colors.green};
      }
      .answer.selected{
        background-color: ${colors.black};
      }
    }
  }
  
  .question-block:last-child{
    margin-bottom: 0;
    .question{
      background: ${colors.skyblue};
    }
  }

  .video-container{
    .video-grid{
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 50px;

      .video{
        line-height: 1.1;
        cursor: pointer;
        .video-img{
          .video-thumbnail{
            max-width: 100%;
          }
          .video-play-btn{
            position: absolute;
            top: calc(50% - 22.5px);
            left: calc(50% - 19px);
            filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.5));
          }
        }
        .video-title{
          font-size: 1.41em;
          font-weight: 500;
          margin-top: 20px;
          max-height: 65px;
          overflow: hidden;
        }
        .video-desc{
          margin-top: 15px;
        }
      }
    }
    .btn{
      margin-top: 50px
    }
    .modal{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.95);
      z-index: 1000;
      
      .video-container{
        max-width: 670px;
        margin: auto;
        padding: 0 15px;
        flex-grow: 1;
      }
      .video-container.hidden{
        display: none;
      }
      .close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        border: 0;
        background: transparent;
        font-size: 1em;
        cursor: pointer;
      }
    }
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    .question-block{
      .answers{
        .answer{
          width: calc(25% - 10px);
        }
      }
    }
    .video-container{
      .video-grid{
        grid-template-columns: 1fr 1fr;
        .video{
          .video-title{
            max-height: 60px;
          }
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .question-block{
      margin-bottom: 80px;
      .question{
        padding: 20px 20px 25px;
        text-align: left;
        font-size: 1.8em;
      }
      .answers{
        .answer{
          width: calc(50% - 10px);
          padding: 15px 10px;
        }
      }
    }

    .video-container{
      .video-grid{
        grid-template-columns: 1fr;
        .video{
          .video-title{
            margin-top: 15px;
            max-height: 54px;
          }
          .video-desc{
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    .question-block{
      .question{
        padding: 15px 15px 20px;
      }
    }
  }
`;

export const ContactUsContainer = styled.section`
  max-width: 970px;
  margin: auto;
  .question-block{
    margin-bottom: 65px;
    .question{
      line-height: 1.1;
      padding: 20px 25px 25px;
      font-size: 2.41em;
      color: ${colors.white};
      background: ${colors.sky};
      margin-bottom: 15px;
    }
    textarea.input-field{
      margin-bottom: 15px;
    }
    .text{
      font-size: 1.04em;
      font-weight: 500;
      white-space: nowrap
    }
    .email{
      padding: 0 15px;
    }
  }
  .question-block:first-child{
    .question{
      background: ${colors.pink};
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .question-block{
      .question{
        font-size: 1.8em;
        padding: 20px 20px 20px
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    .question-block{
      .question{
        padding: 15px 15px 20px;
      }
    }
  }
`;

export const EducationPortalContainer = styled.section`
  .searchbox{
    grid-area: searchbox;
    .search{
      padding-left: 15px;
    }
  }
  .profession{
    grid-area: profession;
  }
  .interests{
    grid-area: interests;
    margin-top: 15px;
  }
  .resource{
    grid-area: resource;
    background: ${colors.skyblue};
    .heading{
      background: ${colors.skyblue}
    }
  }
  .medium{
    grid-area: medium;
    background: ${colors.blue};
    .heading{
      background: ${colors.blue}
    }
  }
  .result-container{
    grid-area: result;
  }
  >.grid-container{
    grid-row-gap: 15px;
    grid-template-areas: 
    '. searchbox searchbox'
    'profession resource medium'
    'profession result result'
    'interests result result'
    '. result result';

    .grid{
      .heading{
        font-size: 1.5em;
        padding: 20px 25px;
        color: ${colors.white};
        line-height: 1.1;
        margin-bottom: 20px;
        .arrow{
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid ${colors.white};
          top: calc(50% - 5px);
          right: 20px;
          transition: transform 0.2s ease;
          display: none;
        }
      }
      .list{
        li:last-child{
          label{
            margin: 0;
          }
        }
      }
    }
    .grid.profession{
      .heading{
        background: ${colors.violet}
      }
    }
    .grid.interests{
      .heading{
        background: ${colors.pink}
      }
    }
  }
  .grid.resource, .grid.medium{
    padding: 20px 25px;
    .heading{
      display: none;
    }
    .list{
      display: flex;
      li{ 
        margin-right: 30px;
        label{
          margin-bottom: 0;
          padding-left: 0;
          font-size: 1.5em;
          color: ${colors.white};
          .checkmark{
            display: none;
          }
        }
      }
      li[data-checked="true"]{
        label{
          color: ${colors.black};
        }
      }
      li:last-child{
        margin: 0
      }
    }
  }
  .result-container{
    .result{
      padding: 30px 60px;
      background: ${colors.white};
      margin-bottom: 25px;
      line-height: 1.1;
      .heading{
        font-weight: 500;
        font-size: 3.3em;
        margin-bottom: 15px;
      }
      .sub-heading{
        font-weight: 400;
        font-size: 1.2em;
        margin-bottom: 25px;
      }
      p{
        max-width: 577px;
      }
      .btn-container{
        align-self: flex-end
      }
    }
    .result:nth-child(7n+1){
      .heading{
        color: ${colors.violet};
      }
    }
    .result:nth-child(7n+2){
      .heading{
        color: ${colors.pink};
      }
    }
    .result:nth-child(7n+3){
      .heading{
        color: ${colors.blue};
      }
    }
    .result:nth-child(7n+4){
      .heading{
        color: ${colors.purple};
      }
    }
    .result:nth-child(7n+5){
      .heading{
        color: ${colors.deepGreen};
      }
    }
    .result:nth-child(7n+6){
      .heading{
        color: ${colors.sky};
      }
    }
    .result:nth-child(7n+7){
      .heading{
        color: ${colors.green};
      }
    }
    .result:last-child{
      margin-bottom: 0
    }
  }
  @media (min-width: ${variables.breakpoint}px) and (max-width: ${variables.containerWidth - 1}px) {
    >.grid-container{
      .grid{
        .heading{
          font-size: 1.32em;
          padding: 15px 20px;
        }
      }
    }
    .grid.resource, .grid.medium{
      padding: 15px 20px;
      .list{
        li{
          margin-right: 20px;
          label{
            font-size: 1.32em;
          }
        }
      }
    }
    .result-container{
      .result{
        padding: 25px 40px;
        .heading{
          font-size: 2.7em;
        }
      }
    }
  }
  @media (max-width: ${variables.breakpoint}px) {
    >.grid-container{
      grid-template-areas: 
      'searchbox searchbox'
      'profession interests'
      'resource medium'
      'result result';

      .grid.resource, .grid.medium{
        padding: 0;
        background: transparent;
        .heading{
          display: block;
        }
        .list{
          li{ 
            margin-right: 0;
            label{
              padding-left: 35px;
              margin-bottom: 15px;
              font-size: inherit;
              color: ${colors.black};
              .checkmark{
                display: block;
              }
            }
          }
          li[data-checked="true"]{
            label{
              color: ${colors.black};
            }
          }
          li:last-child{
            label{
              margin: 0;
            }
          }
        }
      }
      .grid{
        position: relative;
        margin: 0;
        .heading{
          margin: 0;
          padding: 15px 20px;
          .arrow{
            display: block
          }
        }
        .list{
          position: absolute;
          left: 0;
          top: 100%;
          right: 0;
          padding: 25px;
          background: ${colors.white};
          border: 1px solid ${colors.border};
          border-top: 0;
          transition: all 0.2s ease;
          z-index: 100;
          display: none;
          opacity: 0;
        }
      }
      .grid.open{
        .heading {
          .arrow{
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }
        .list{
          display: block;
          opacity: 1;
          transition: all 0.2s ease;
        }
      }
    }
    .result-container{
      .result{
        padding: 20px 25px;
        .heading{
          font-size: 2.5em;
        }
        .btn-container{
          margin-top: 15px
        }
        >.flex-container{
          flex-direction: column;
        }
        p{
          max-width: 100%
        }
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    >.grid-container{
      grid-template-areas: 
        'searchbox'
        'profession'
        'interests'
        'resource'
        'medium'
        'result';
    }
  }
`;

export const Footer = styled.footer`
  padding-bottom: 225px;
  .social-list{
    li{
      padding: 0 33px;
      margin-bottom: 10px;
      img{
        width: 58px;
      }
    }
    li:last-child{
      margin-right: 0;
    }
  }
  .menu-list{
    margin-top: 50px;
    li{
      padding: 0 15px;
      a{
        color: ${colors.black};
      }
    }
  }
  .copyright{
    margin-top: 25px;
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    padding-bottom: 140px;
    .social-list{
      li{
        padding: 0 8px;
        img {
          width: 45px;
        }
      }
    }
  }
`;

export const PopupFormContainer = styled.div.attrs({
  className: "popup-form-container"
})`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 100;
  color: ${colors.black};
  .form-container{
    padding: 40px;
    background: ${colors.white};
    position: relative;
    width: 500px;
    h2{
      font-size: 2.5em;
      margin-bottom: 15px;
    }
    p{
      margin-bottom: 15px;
    }
    .alert-container{
      font-size: 1.15em
    }
    .alert-container.error{
      .message{
        color: ${colors.pink}
      }
      a{
        color: ${colors.blue}
      }
    }
    .alert-container.success{
      .message{
        color: ${colors.deepGreen}
      }
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    .form-container{
      width: 100%;
    }
  }
`;
