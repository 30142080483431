import {createGlobalStyle} from "styled-components";
import {colors, variables} from "./colors";
import BarlowCondensedBold from "../assets/fonts/BarlowCondensed-Bold.ttf";
import BarlowCondensedMedium from "../assets/fonts/BarlowCondensed-Medium.ttf";
import BarlowCondensedRegular from "../assets/fonts/BarlowCondensed-Regular.ttf";
import BarlowCondensedSemiBold from "../assets/fonts/BarlowCondensed-SemiBold.ttf";

import "swiper/css";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "BarlowCondensed";
    src: url(${BarlowCondensedBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "BarlowCondensed";
    src: url(${BarlowCondensedSemiBold}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "BarlowCondensed";
    src: url(${BarlowCondensedMedium}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "BarlowCondensed";
    src: url(${BarlowCondensedRegular}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, svg {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
      box-sizing: border-box;
  }
  body {
    margin: 0;
    font-family: "BarlowCondensed";
    font-weight: 300;
    font-size: 20px;
    color: ${colors.black};
    line-height: 1.5;
    background-color: ${colors.bodyBackground};
    overflow-x: hidden;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: initial;
    width: 100%;
    a{
      text-decoration: none;
    }
    img{
      display: block;
    }
    .relative{
      position: relative;
    }
    .text-uppercase{
      text-transform: uppercase
    }
    .text-right{
      text-align: right
    }
    .text-left{
      text-align: left
    }
    .text-center{
      text-align: center
    }
    .flex-container{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .flexbox{
        flex-grow: 1;
        align-self: auto;
      }
      .flexbox.full-width{
        width: 100%;
      }
      .flexbox.half-width{
        width: 50%
      }
    }
    .flex-container.column{
      flex-direction: column;
    }
    .flex-container.wrap{
      flex-wrap: wrap;
    }
    .vertical-middle{
      align-items: center;
    }
    .vertical-end{
      align-items: flex-end;
    }
    .justify-center{
      justify-content: center;
    }
    .justify-end{
      justify-content: end;
    }
    .btn{
      display: inline-block;
      padding: 15px 20px;
      margin: 0;
      width: auto;
      font-size: 1em;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      user-select: none;
      position: relative;
      font-weight: 600;
      border: 0;
      outline: none;
      color: ${colors.black};
      border: 0;
      background: ${colors.white};
      font-family: "BarlowCondensed";
    }
    .btn.reverse{
      background: ${colors.black};
      color: ${colors.white};
    }
    .btn.green{
      color: ${colors.textGreen};
      background: transparent;
    }
    .btn.black{
      color: ${colors.black};
      background: transparent;
      font-weight: 500;
    }
    .btn.blue{
      background: ${colors.blue};
      color: ${colors.white};
    }
    .btn.deep-green{
      background: ${colors.deepGreen};
      color: ${colors.white};
    }
    .btn.no-padding{
      padding: 0
    }
    .btn.text-left{
      text-align: left
    }
    .btn[disabled]{
      opacity: 0.6
    }

    .grid-container{
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      position: relative;
    }
    section{
      .section-heading{
        font-size: 3.2em;
        line-height: 1;
        font-weight: 500;
        margin-bottom: 40px;
      }
      .view-more-container{
        margin-top: 10px;
      }
    }
    .form-group{
      margin-bottom: 25px;
      label[for]{
        font-size: 1.2em;
        display: block;
        color: ${colors.black};
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
    .input-field{
      display: block;
      border: 1px solid ${colors.border};
      position: relative;
      padding: 10px 15px;
      width: 100%;
      background-color: ${colors.white};
      height: 54px;
      font-size: 1em;
      font-weight: 300;
      z-index: 51;
      resize: none;
      position: relative;
      line-height: 1.4;
      outline: none;
      color: ${colors.black};
      font-family: "BarlowCondensed";
    }
    textarea.input-field {
      height: 180px;
    }
    .custom-input{
      display: flex;
      align-items: center;
      min-height: 25px;
      position: relative;
      padding-left: 35px;
      margin-bottom: 15px;
      cursor: pointer;
      user-select: none;
      text-transform: capitalize;
      line-height: 1.1;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid ${colors.border};
        background: ${colors.white};
      }
    }
    .custom-input[data-type="checkbox"]{
      .checkmark:after{
        content: "";
        position: absolute;
        opacity: 0;
        visibility: hidden;
        left: 8px;
        top: 1px;
        width: 5px;
        height: 15px;
        border: 1px solid ${colors.white};
        background: ${colors.deepGreen};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        transition: opacity 0.2s ease 0s;
      }
      input:checked ~ .checkmark{
        background: ${colors.deepGreen};
        border: 1px solid ${colors.deepGreen};
      }
      input:checked ~ .checkmark:after {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease 0s;
      }
    }
    .loading-img{
      animation: rotation 2s linear infinite;
      max-width: 40px;
    }
    .loading-text{
      padding-left: 20px
    }
    .close{
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 101;
      background: transparent;
      border: 0;
      padding: 0;
    }
    .close:before, .close:after{
      content: "";
      position: absolute;
      z-index: 100;
      height: 2px;
      width: 25px;
      background: ${colors.black};
      top: 24px;
      right: 13px;
    }
    .close:before{
      transform: rotate(45deg);
    }
    .close:after{
      transform: rotate(-45deg);
    }
  }
  @media (min-width: ${variables.breakpoint}px) and 
  (max-width: ${variables.containerWidth - 1}px) {
    body{
      font-size: 20px;
    }
  }
  @media (min-width: ${variables.mobilePortrait}px) and (max-width: ${variables.breakpoint}px) {
    body{
      font-size: 18px;
    }
  }
  @media (max-width: ${variables.mobilePortrait}px) {
    body{
      font-size: 16px;
      section .section-heading{
        font-size: 2.85em;
        margin-bottom: 30px;
      }
      .input-field{
        height: 46px;
      }
    }
  }
  @media (max-width: ${variables.mobileSmallScreen}px) {
    body{
      section .section-heading{
        font-size: 2.6em;
      }
    }
  }
`
