import React, {useState} from "react";
import {Link} from "gatsby";
import {Header} from "../../style";
import {links} from "../common.config";
import LogoText from "../../assets/images/logo-text.svg";
import LogoImage from "../../assets/images/logo-image.svg";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Header>
      <div className="flex-container flex-container vertical-middle">
        <div className="flexbox logo">
          <Link to="/" className="flex-container vertical-middle">
            <img src={LogoImage} className="logo-image" alt="cpoem logo"/>
            <img src={LogoText} className="logo-text" alt="cpoem text"/>
          </Link>
        </div>
        <div className={`menu-list${menuOpen ? " open" : ""}`}>
          <div
            className="hamberger"
            role="button"
            tabIndex="0"
            aria-hidden={menuOpen ? "true" : "false"}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {
              ["one", "two", "three"].map(el => <span key={el} className={`bar ${el}`}></span>)
            }
          </div>
          <ul className="flex-container vertical-middle">
          {
            links.map(({link, text}, index) => (
              <li key={index}>
                <Link to={link} target="_self">{text}</Link>
              </li>
            ))
          }
          </ul>

        </div>
      </div>
    </Header>
  );
};

export default NavBar;