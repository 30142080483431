export const colors = {
  grey: "#E6E6E6",
  white: "#ffffff",
  black: "#000000",
  blue: "#44A8B7",
  green: "#96BC62",
  yellow: "#FFDF7F",
  pink: "#F3777D",
  purple: "#B1709B",
  violet: "#7360B6",
  skyblue: "#7BB1F8",
  sky: "#5CD6FD",
  textGreen: "#96DC1E",
  deepGreen: "#00B446",
  background: "#F1F1F1",
  bodyBackground: "#FAFAFA",
  border: "#CCCCCC"
};

export const variables = {
  breakpoint: 1023,
  mobileSmallScreen: 320,
  mobilePortrait: 767,
  containerWidth: 1200
};