import React from "react";
import {Link} from "gatsby";
import moment from "moment";
import {Footer} from "../../style";
import {links} from "../common.config";

import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import linkedin from "../../assets/images/linkedin.svg";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";

const FooterBar = () => {
  const socialLinks = [{
    name: "twitter",
    icon: twitter,
    link: "https://twitter.com/CpoemF"
  }, {
    name: "instagram",
    icon: instagram,
    link: "https://www.instagram.com/cpoem"
  }, {
    name: "facebook",
    icon: facebook,
    link: "https://www.facebook.com/cpoem"
  }, {
    name: "youtube",
    icon: youtube,
    link: "https://www.youtube.com/channel/UCGTbC_ZFyAtF95i-wA92J_A"
  }, {
    name: "linkedin",
    icon: linkedin,
    link: "https://www.linkedin.com/in/cpoem"
  }];

  return (
    <Footer className="section-spacing">
      <ul className="social-list flex-container justify-center wrap">
        {
          socialLinks.map(el => {
            const {icon, link, name} = el;
            return (
              <li className={name} key={name}>
                <a href={link} target="_blank" rel="noreferrer">
                  <img src={icon} className="social-icon" alt={name}/>
                </a>
              </li>
            );
          })
        }
      </ul>
      <ul className="menu-list flex-container justify-center wrap">
        {
          links.map(({link, text}, index) => (
            <li key={index}>
              <Link to={link} target="_self">{text}</Link>
            </li>
          ))
        }
      </ul>
      <div className="copyright text-center">
        Copyright &#x24B8; <span>{moment(new Date()).format("YYYY")}</span> CPOEM. All rights reserved.
      </div>
    </Footer>
  )
};

export default FooterBar;