export const links = [{
  link: "/about-us/",
  text: "About Us"
}, {
  link: "/the-team/",
  text: "The Team"
}, {
  link: "/events/",
  text: "Events"
}, 
// {
//   link: "/education/",
//   text: "Education"
// }, 
// {
//   link: "/patient-support/",
//   text: "Patient Support"
// }, 
{
  link: "/contact-us/",
  text: "Contact Us"
}];

export const professionList = [{
  text: "Clinical",
  value: "clinical"
}, {
  text: "Physician",
  value: "physician"
}, {
  text: "Nurse",
  value: "nurse"
}, {
  text: "Oncology fellow",
  value: "oncology"
}];

export const interestList = [{
  text: "Radiation oncology",
  value: "radiation"
}, {
  text: "Surgical oncology",
  value: "surgical"
}, {
  text: "Pathology",
  value: "pathology"
}, {
  text: "Palliative medicine",
  value: "palliative"
}, {
  text: "Genetics",
  value: "genetics"
}, {
  text: "Radiology",
  value: "radiology"
}];

export const resourceList = [{
  text: "Workshop",
  value: "workshop"
}, {
  text: "Q&A based learning",
  value: "qa-learning"
}, {
  text: "Exam prep",
  value: "exam-prep"
}];

export const mediumList = [{
  text: "Video",
  value: "video"
}, {
  text: "Blog",
  value: "blog"
}, {
  text: "Articles",
  value: "articles"
}];

export const apiKey = "AIzaSyDS-0UcmitFhF7iPQTlI44Tjie3gkLhXmk";
export const channelId = "UCGTbC_ZFyAtF95i-wA92J_A";

const isEmptyObject = (object) => !(object && Object.keys(object).length);

export const getUrlParams = () => {
  const result = {};
  if(typeof window !== "undefined" && window.location && window.location.search){
    const query = window.location.search.substr(1);
    query.split("&").forEach(part => {
      const item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return result;
};

export const createURLQueryParam = (object) => {
  if(isEmptyObject(object)){
    return "";
  }

  const keys = Object.keys(object)
  const querStr = keys.reduce((data, el) => {
    if(object[el]){
      data.push(`${el}=${object[el]}`);
    }
    return data;
  }, []).join("&");
  return querStr ? `?${querStr}` : querStr;
};

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const mailChimpLink = "https://cpoem.us6.list-manage.com/subscribe/post?u=968cfbb55c0baa55156682e01&id=fa517cc7ec";
